  // Adjusting Margins

  $(window).on('load resize', function() {
    var w = window.innerWidth ? window.innerWidth : $(window).width();
    var h = $('header').outerHeight(true);
    if (w > 600) {
      $('#navi-left').css('padding-top', h + 'px');
    } else {
      $('#navi-left').css('padding-top', '0');
    }
  });
